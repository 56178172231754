import {ReactNode, useContext, useEffect, useState} from "react";
import AppContext from "./context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useApi} from "./api";

type Props = {
    children: ReactNode
    onLoggedIn: (username: string, sessionId: string|null) => void,
}

export default ({ children, onLoggedIn }: Props) => {
    const api = useApi()

    const { loggedIn } = useContext(AppContext)

    const [ loading, setLoading ] = useState<boolean>(true)
    const [ loggingIn, setLoggingIn ] = useState<boolean>(false)
    const [ checked, setChecked ] = useState<boolean>(false)

    const [ error, setError ] = useState<string|null>(null)
    const [ username, setUsername ] = useState<string>('')
    const [ password, setPassword ] = useState<string>('')

    useEffect(() => {
        (async () => {
            const info = await api.getAuthInfo()

            if (info.loggedIn) {
                onLoggedIn(info.username, null)
                setLoading(false)
            }

            setChecked(true)
        })()
    }, []);

    const login = () => {
        (async () => {
            setError(null)
            setLoggingIn(true)
            const res = await api.login(username, password)
            if (typeof res.error !== 'undefined') {
                setError(res.error)
            }
            if (typeof res.sessionId !== 'undefined') {
                onLoggedIn('admin', res.sessionId)
            }
            setLoggingIn(false)
        })()
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        login()
    }

    return (
        <>
            { !checked ? (
                <>
                    <FontAwesomeIcon icon={ faSpinner } size={"3x"} spin />
                </>
            ) : (
                <>
                    { loggedIn ? children : (
                        <>
                            <h1>Login</h1>

                            { error ? (
                                <div className="error">
                                    { error }
                                </div>
                            ) : null }

                            <form action="" onSubmit={onSubmit}>
                                <input type="text" value={ username } onChange={ e => setUsername(e.target.value) } placeholder="Username" disabled={ loggingIn } autoFocus/>
                                <input type="password" value={ password } onChange={ e => setPassword(e.target.value) } placeholder="Password" disabled={ loggingIn }/>

                                <button type="submit" disabled={loggingIn}>
                                    Login
                                    {loggingIn ? (
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    ) : null}
                                </button>
                            </form>
                        </>
                    )}
                </>
            )}
        </>
    )
}