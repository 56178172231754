import {createContext} from "react";
import {ProcessListType} from "../api/ProcessListClient";
import {VideoSortMethodType} from "../lib/videoSortMethods";

export type AppContextType = {
    loggedIn: boolean
    username: string|null
    dirLoading: boolean
    currentDir: VideoDirectory | null
    setCurrentDir: (dir: VideoDirectory | null) => void
    loadDirectory: (ev: any, dir: VideoDirectory | null) => void
    directories: VideoDirectory[]
    files: VideoFile[]
    selectedVideo: VideoFile | null
    prevVideo: VideoFile | null
    nextVideo: VideoFile | null
    setSelectedVideo: (video: VideoFile | null) => void
    processList: ProcessListType | null
    darkMode: boolean,
    setDarkMode: (enabled: boolean) => void
    startIndexing: () => void
    tags: string[]
    setTags: (tags: string[]) => void
    videoLoop: boolean
    setVideoLoop: (enabled: boolean) => void
    videoAutoPlay: boolean
    setVideoAutoPlay: (enabled: boolean) => void
    reloadView: (e: any|undefined) => void
    sortType: VideoSortMethodType
    setSortType: (type: VideoSortMethodType) => void
    limitToFileType: string | null
    setLimitToFileType: (fileType: string | null) => void
    fetchingAdditionalFiles: boolean
    maybeFetchAdditionalFiles: () => void
    maxFiles: number
    hasMoreFiles: () => boolean
}

const AppContext = createContext<AppContextType>({
    loggedIn: false,
    username: null,
    dirLoading: false,
    currentDir: null,
    setCurrentDir: (dir) => null,
    loadDirectory: (ev, dir) => null,
    directories: [],
    files: [],
    prevVideo: null,
    selectedVideo: null,
    nextVideo: null,
    setSelectedVideo: () => null,
    processList: null,
    darkMode: false,
    setDarkMode: () => null,
    startIndexing: () => null,
    tags: [],
    setTags: () => null,
    videoLoop: true,
    setVideoLoop: () => null,
    videoAutoPlay: false,
    setVideoAutoPlay: () => null,
    reloadView: () => null,
    sortType: 'none',
    setSortType: () => null,
    limitToFileType: null,
    setLimitToFileType: () => null,
    fetchingAdditionalFiles: false,
    maybeFetchAdditionalFiles: () => null,
    maxFiles: 0,
    hasMoreFiles: () => false,
})

export default AppContext