import {useContext, useEffect, useState} from "react";

import './FileQueue.scss'
import AppContext from "../context/AppContext";
import TimeSince from "./TimeSince";
import {ProcessListEntryQueued, ProcessListType} from "../api/ProcessListClient";

export default () => {
    const { processList } = useContext(AppContext)
    const [ now, setNow ] = useState<number>(0)

    const sortByType = (a: ProcessListEntryQueued, b: ProcessListEntryQueued) => {
        if (a.type === b.type) {
            return 0
        }
        return a.type === 'directory' ? 1 : -1
    }

    useEffect(() => {
        const dateInterval = setInterval(() => {
            const date = new Date()
            setNow(Math.floor(date.getTime() / 1000))
        }, 1000)

        return () => {
            window.clearInterval(dateInterval)
        }
    }, [])

    return (
        <div className="file-queue">
            { !processList ? (
                'No active processed'
            ): (
                <>
                    <div className="left">
                        <h1>
                            <>Queued ({processList.queued})</>
                        </h1>
                    </div>
                    <div className="right">
                        <h1>Processing ({processList.processing.length})</h1>
                        <table>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Queued Since</th>
                                <th>Processed Since</th>
                                <th>Path</th>
                                <th>Status</th>
                                <th>Error</th>
                            </tr>
                            </thead>
                            <tbody>
                            {processList.processing.sort(sortByType).map(file => (
                                <tr key={file.path}>
                                    <td>{file.fileType}</td>
                                    <td><TimeSince timestamp={ file.queued } now={ now }/></td>
                                    <td><TimeSince timestamp={ file.started } now={ now }/></td>
                                    <td>{file.path}</td>
                                    <td>
                                        {file.videoStatus ? (
                                            <>
                                                { file.videoStatus.currentAction } ({ file.videoStatus.percent }%)
                                            </>
                                        ) : <></> }
                                        {file.photoStatus ? (
                                            <>
                                                { file.photoStatus.currentAction } ({ file.photoStatus.percent }%)
                                            </>
                                        ) : <></> }
                                    </td>
                                    <td>
                                        {file.videoStatus ? (
                                            <>
                                                { file.videoStatus.error }
                                            </>
                                        ) : <></> }
                                        {file.photoStatus ? (
                                            <>
                                                { file.photoStatus.error }
                                            </>
                                        ) : <></> }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    )
}