import React, {createRef, useContext, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import AppContext from "./context/AppContext";
import {v4 as uuidv4} from 'uuid';

import './HeaderItemList.scss'

export type HeaderMenuItem = {
    key: any
    active: boolean
    element: React.ReactNode
}

type Props = React.HTMLAttributes<HTMLUListElement> & {
    items: HeaderMenuItem[]
    collisionWatchSelector?: string
}

export default ({ items, collisionWatchSelector }: Props) => {
    const { dirLoading } = useContext(AppContext)

    const desktopRef = createRef<HTMLUListElement>()

    const [ mobile, setMobile ] = useState<boolean>(false)
    const [ mobileOpen, setMobileOpen ] = useState<boolean>(false)

    const [ domId, setDomId ] = useState<string>(uuidv4())

    const mobileFlagRef = useRef<boolean>()
    mobileFlagRef.current = mobile

    const desktopElRef = useRef<HTMLUListElement>()
    desktopElRef.current = desktopRef.current as HTMLUListElement

    const checkCollision = () => {
        if (!collisionWatchSelector) {
            return
        }

        const desktopItem = document.getElementById(domId)?.querySelector('.desktop')

        const otherEl = document.querySelector(collisionWatchSelector)
        if (!otherEl) {
            throw Error(`unknown element: ${collisionWatchSelector}`)
        }

        const menuBox = desktopItem?.getBoundingClientRect()
        const otherElementBox = otherEl.getBoundingClientRect()

        if (menuBox && otherElementBox) {
            let space
            if (menuBox.x < otherElementBox.x) {
                space = otherElementBox.x - (menuBox.x + menuBox.width)
            } else {
                space = menuBox.x - (otherElementBox.x + otherElementBox.width)
            }

            if (!mobileFlagRef.current && space <= 0) {
                setMobile(true)
            }
            if (mobileFlagRef.current && space > 0) {
                setMobile(false)
            }
        }
    }

    const toggleMobileOpen = (e: any) => {
        e.preventDefault()
        setMobileOpen(!mobileOpen)
    }

    useEffect(() => {
        if (!collisionWatchSelector) {
            return
        }

        checkCollision()

        window.addEventListener('resize', checkCollision)

        return () => {
            window.removeEventListener('resize', checkCollision)
        }
    }, []);

    useEffect(() => {
        if (!dirLoading) {
            checkCollision()
        }
    }, [ items ]);

    useEffect(() => {
        if (!mobile || dirLoading) {
            setMobileOpen(false)
        }
    }, [ mobile, dirLoading ])

    return (
        <div className={ `header-item-list ${mobile ? 'mobile' : ''}` } id={ domId }>
            <ul className="desktop" ref={ desktopRef }>
                {items.map(item => (
                    <li key={item.key} className={ item.active ? 'active' : '' }>
                        {item.element}
                    </li>
                ))}
            </ul>
            <ul className={`mobile ${mobileOpen ? 'open' : ''}`}>
                <li>
                    <a href="#" onClick={toggleMobileOpen}>
                        <FontAwesomeIcon icon={ faBars }/>
                    </a>
                    <ul>
                        {items.map(item => (
                            <li key={item.key} className={ item.active ? 'active' : '' }>
                                {item.element}
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </div>
    )
}