import {useContext, useEffect} from "react";
import AppContext from "../context/AppContext";
import {EventSourcePlus} from "event-source-plus";

export type ProcessListEntryQueued = {
    type: string
    fileType: string
    path: string
    queued: number
}

export type ProcessListEntryProcessing = ProcessListEntryQueued & {
    started: number
    videoStatus: any
    photoStatus: any
}

export type ProcessListType = {
    queued: ProcessListEntryQueued[]
    processing: ProcessListEntryProcessing[]
}

class ProcessListClient
{
    private listeners: ((status: ProcessListType) => void)[] = []

    start() {
        const headers: { [key: string]: string } = {}
        const sessionId = localStorage.getItem('session-id') || ''

        if (sessionId) {
            headers['Authorization'] = `Bearer ${sessionId}`
        }

        const stream = new EventSourcePlus((process.env.REACT_APP_BASE_URL || '') + '/api/processlist/live', {
            headers,
        })

        stream.listen({
            onMessage: (message) => {
                const data = JSON.parse(message.data)
                this.listeners.map(cb => cb(data))
            },
            onResponseError: (error) => {
                console.log('STATUS ERROR', error)
            }
        })
    }

    public addListener(listener: ((status: ProcessListType) => void)) {
        this.listeners.push(listener)
    }

    public removeListener(listener: ((status: ProcessListType) => void)) {
        this.listeners = this.listeners.filter(other => other !== listener)
    }
}

let client: ProcessListClient

export const useProcessListClient = () => {
    if (!client) {
        client = new ProcessListClient()
    }

    const loggedIn = useContext(AppContext)

    useEffect(() => {
        if (loggedIn) {
            client.start()
        }
    }, [ loggedIn ]);

    return client
}