import React, {ReactElement, useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";
import DirectoryList from "../components/DirectoryList";
import VideoList from "../components/VideoList";

import './FileBrowser.scss'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

export default () => {
    const location = useLocation()
    const navigate = useNavigate()

    const defaultTerm = location.pathname.split('/search/').pop() || ''

    const { dirLoading, directories, files, currentDir, loadDirectory, tags, maxFiles } = useContext(AppContext)

    const [ reversedParents, setReversedParents ] = useState<VideoDirectory[]>([])
    const [ searchTerm, setSearchTerm ] = useState<string>(defaultTerm)
    const [ tagSearchTerm, setTagSearchTerm ] = useState<string>('')

    useEffect(() => {
        let parents: VideoDirectory[] = []
        if (currentDir && currentDir.parents) {
            parents = [ ...currentDir.parents ]
            parents.reverse()
        }
        setReversedParents(parents)
    }, [currentDir]);

    const directoryFilter = (d: VideoDirectory) => {
        if (d.name.startsWith('.')) {
            return false
        }

        if (!d.fileCount && !d.childCount) {
            return false
        }

        return true
    }

    const isTagList = location.pathname.startsWith('/tags/')
    const isSearch = location.pathname.startsWith('/search/')

    let selectedTags: string[] = []

    if (isTagList) {
        let path = location.pathname
        if (path.startsWith('/')) {
            path = path.substring(1)
            const chunks = path.split('/')
            selectedTags = chunks[1]
                .split(',')
                .map(t => decodeURIComponent(t.trim()))
                .filter(t => !!t)
        }
    }


    const dirCount = directories.filter(directoryFilter).length

    const items: { key: any, element: ReactElement<HTMLAnchorElement> }[] = []

    if (currentDir) {
        items.push({ key: 'home',element: (
                <Link to={'/'}>
                    <FontAwesomeIcon icon={faHome}/>
                </Link>
            )})

        for (const p of reversedParents) {
            items.push({ key: p.path,element: (
                    <a href={`/dir/${p.hash}`} onClick={e => loadDirectory(e, p)} key={p.hash}>
                        {p.name}
                    </a>
                )})
        }
        items.push({ key: 'current-dir',element: (
                <a href={`/dir/${currentDir.hash}`} onClick={e => loadDirectory(e, currentDir)} key={currentDir.hash}>
                    {currentDir.name}
                </a>
            )})
    }

    const onSearchSubmit = (e: any) => {
        e.preventDefault()
        navigate(`/search/${searchTerm}`)
    }

    const sortTags = (a: string, b: string) => {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1
    }

    const filterTags = (tag: string) => {
        return tag.toLowerCase().includes(tagSearchTerm.toLowerCase())
    }

    return (
        <>
            <div className="file-browser">
                { items.length ? (
                    <ul className="breadcrumbs">
                        {items.map(item => (
                            <li key={item.key}>
                                {item.element}
                            </li>
                        ))}
                    </ul>
                ) : <></> }

                { isTagList ? (
                    <div className="tags">
                        <input type="text" placeholder="Filter tags..." value={ tagSearchTerm } onChange={ e => setTagSearchTerm(e.target.value) } />

                        <ul className="taglist large" style={{ marginTop: '10px' }}>
                            { tags.sort(sortTags).filter(filterTags).map(tag => (
                                <li key={tag} className={ (selectedTags.includes(tag) ? 'selected' : '') + (selectedTags.length ? ' with-actions' : '' ) }>
                                    <Link to={`/tags/${tag}`}>
                                        { tag }
                                    </Link>
                                    <div className="actions">
                                        { selectedTags.includes(tag) ? (
                                            <Link to={ `/tags/${selectedTags.filter(t => t !== tag)}` }>
                                                <FontAwesomeIcon icon={ faMinus }/>
                                            </Link>
                                        ) : (
                                            <Link to={ `/tags/${[ ...selectedTags, tag ].join(',')}` }>
                                                <FontAwesomeIcon icon={ faPlus }/>
                                            </Link>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : <></> }

                { isSearch ? (
                    <div className="search">
                        <form action="" onSubmit={ onSearchSubmit }>
                            <input type="text" placeholder={"Search..."} value={ searchTerm } onChange={ (e) => setSearchTerm(e.target.value) } disabled={ dirLoading }/>
                            <button type="submit" disabled={ dirLoading }>
                                Search
                            </button>
                        </form>
                    </div>
                ) : null}

                { selectedTags.length ? (
                    <div>
                        Selected tags: { selectedTags.join(', ') }
                    </div>
                ) : null }

                {!dirCount && !files ? (
                    <h2>Empty directory</h2>
                ) : <></>}

                <DirectoryList loading={dirLoading} dirs={directories}/>

                { maxFiles ? (
                    <h2>
                        { maxFiles } files
                    </h2>
                ) : null }

                {files && files.length ? (
                    <h3>
                        Loaded:&nbsp;
                        {files.filter(f => f.fileType === 'video').length} Videos, &nbsp;
                        {files.filter(f => f.fileType === 'photo').length} Photos
                    </h3>
                ) : <></> }

                <VideoList canBeSelected={ true } loading={ dirLoading } files={ files } showDirectory={ selectedTags.length > 0 || isSearch }/>
            </div>
        </>
    )
}