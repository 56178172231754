import Modal from "../general/Modal";
import {createRef, useContext, useEffect, useState} from "react";
import AppContext from "../../context/AppContext";
import {useApi} from "../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

type Props = {
    videoIds: string[]
    onClose: () => void
    onChanged: (newTags: string[]) => void
}

export default ({ videoIds, onClose, onChanged }: Props) => {
    const api = useApi()

    const newTagInputRef = createRef<HTMLInputElement>()

    const { tags, setTags } = useContext(AppContext)

    const [ saving, setSaving ] = useState<boolean>(false)

    const [ newTags, setNewTags ] = useState<string[]>([])
    const [ ctrlPressed, setCtrlKeyPressed ] = useState<boolean>(false)
    const [ saveRequested, setSaveRequested ] = useState<boolean>(false)

    useEffect(() => {
        if (saveRequested) {
            ;(async () => {
                await save()
                setSaveRequested(false)
            })()
        }
    }, [saveRequested]);

    const save = async (e: any|undefined = undefined) => {
        if (typeof e !== 'undefined') {
            e.preventDefault()
            e.stopPropagation()
        }

        setSaving(true)

        const res: any = await api.bulkAddTags(videoIds, newTags)

        if (typeof res.error !== 'undefined') {
            setSaving(false)
            alert(res.error)
            return
        }

        for (const tag of newTags) {
            if (!tags.includes(tag)) {
                setTags([ ...tags, tag ])
            }
        }

        setSaving(false)
        onChanged(newTags)

        onClose()
    }

    const addTag = () => {
        const tag = newTagInputRef.current?.value
        if (tag && !newTags.includes(tag)) {
            setNewTags([...newTags, tag])
        }
        if (newTagInputRef.current) {
            newTagInputRef.current.value = ''
            newTagInputRef.current.focus()
        }
    }

    const onInputKeyUp = async (e: any) => {
        if (e.key === "Enter") {
            await addTag()

            if (ctrlPressed) {
                setSaveRequested(true)
            }
        }
    }

    const removeTag = (e: any, tag: string) => {
        e.preventDefault()
        setNewTags(newTags.filter(t => t !== tag))
    }

    const onKeyDown = (e: any) => {
        if (e.key === 'Control') {
            setCtrlKeyPressed(true)
        }
    }

    const onKeyUp = (e: any) => {
        if (e.key === 'Control') {
            setCtrlKeyPressed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown)
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, []);

    useEffect(() => {
        if (newTagInputRef.current) {
            newTagInputRef.current.focus()
        }
    }, [ newTagInputRef ]);

    return (
        <Modal title="Add additional tags" onClose={onClose}>
            { newTags.length ? (
                <ul className="taglist">
                    {newTags.map(tag => (
                        <li key={tag}>
                            {tag}
                            <a href="#" onClick={e => removeTag(e, tag)}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </a>
                        </li>
                    ))}
                </ul>
            ) : null }

            <input type="text" ref={newTagInputRef} onKeyUp={onInputKeyUp} list="tags" placeholder={'New Tag'}
                   disabled={saving}/>
            <datalist id="tags">
                {tags.map(tag => (
                    <option key={tag} value={tag}/>
                ))}
            </datalist>

            <button onClick={save} disabled={saving}>
                {saving ? 'Saving...' : 'Save'}
            </button>
        </Modal>
    )
}