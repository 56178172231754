import React, {useContext, useEffect} from "react";
import AppContext from "../context/AppContext";

import './Header.scss'
import Breadcrumbs from "./Breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoon, faRepeat, faSun} from "@fortawesome/free-solid-svg-icons";

export default () => {
    const { currentDir, darkMode, setDarkMode, startIndexing } = useContext(AppContext)

    useEffect(() => {
        const darkMode = localStorage.getItem('darkmode')
        if (darkMode) {
            document.body.classList.add('dark')
        }
    }, []);

    const onStartIndexing = () => {
        if (window.confirm('Would you really like to re-index this directory?')) {
            startIndexing()
        }
    }
    return (
        <header>
            <div className="left">
                <Breadcrumbs/>
            </div>

            <h1 id="app-title">{currentDir?.name || 'Video Viewer'}</h1>

            <div className="right">
                <ul>
                    <li>
                        <a href="#" onClick={onStartIndexing} title="Re-Index this directory">
                            <FontAwesomeIcon icon={faRepeat}/>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={() => setDarkMode(!darkMode)} title="Toggle light/dark">
                            <FontAwesomeIcon icon={darkMode ? faSun : faMoon}/>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    )
}