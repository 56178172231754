export type VideoSortMethodType = 'none' | 'reverse' | 'filenameOldestFirst' | 'filenameNewestFirst'

export const videoSortMethods: {
    [type: string]: {
        name: string
        field: string
        dir: string
    }} = {
    none: {
        name: '(Timestamp) newest first',
        field: 'timestamp',
        dir: 'desc',
    },
    reverse: {
        name: '(Timestamp) oldest first',
        field: 'timestamp',
        dir: 'asc',
    },
    filenameNewestFirst: {
        name: '(Filename) newest first',
        field: 'timestamp',
        dir: 'desc',
    },
    filenameOldestFirst: {
        name: '(Filename) oldest first',
        field: 'filename',
        dir: 'asc',
    },
}