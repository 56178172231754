import './TagList.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {createRef, useContext, useEffect, useRef, useState} from "react";
import Modal from "./general/Modal";
import AppContext from "../context/AppContext";
import {useApi} from "../api";
import {createPortal} from "react-dom";
import {Link} from "react-router-dom";

type Props = {
    video: VideoFile
    showAdd?: boolean
}

export default ({ video, showAdd = false }: Props) => {
    const api = useApi()

    const { tags, setTags } = useContext(AppContext)

    const [ showAddModal, setShowAddModal ] = useState<boolean>(false)
    const [ saving, setSaving ] = useState<boolean>(false)

    const [ newTags, setNewTags ] = useState<string[]>([])
    const [ ctrlPressed, setCtrlKeyPressed ] = useState<boolean>(false)
    const [ saveRequested, setSaveRequested ] = useState<boolean>(false)

    const newTagInputRef = createRef<HTMLInputElement>()

    useEffect(() => {
        if (saveRequested) {
            ;(async () => {
                await save()
                setSaveRequested(false)
            })()
        }
    }, [saveRequested]);

    const onAddClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        setNewTags([...(video?.tags || [])])
        setShowAddModal(true)
    }

    const save = async (e: any|undefined = undefined) => {
        if (typeof e !== 'undefined') {
            e.preventDefault()
            e.stopPropagation()
        }

        setSaving(true)

        const res: any = await api.setTags(video.hash, newTags)

        if (typeof res.error !== 'undefined') {
            setSaving(false)
            alert(res.error)
            return
        }

        video.tags = newTags
        setShowAddModal(false)

        for (const tag of newTags) {
            if (!tags.includes(tag)) {
                setTags([ ...tags, tag ])
            }
        }

        setSaving(false)
    }

    const addTag = () => {
        const tag = newTagInputRef.current?.value
        if (tag && !newTags.includes(tag)) {
            setNewTags([...newTags, tag])
        }
        if (newTagInputRef.current) {
            newTagInputRef.current.value = ''
            newTagInputRef.current.focus()
        }
    }

    const removeTag = (e: any, tag: string) => {
        e.preventDefault()
        setNewTags(newTags.filter(t => t !== tag))
    }

    const onInputKeyUp = async (e: any) => {
        if (e.key === "Enter") {
            await addTag()

            if (ctrlPressed) {
                setSaveRequested(true)
            }
        }
    }

    const onKeyDown = (e: any) => {
        if (e.key === 'Control') {
            setCtrlKeyPressed(true)
        }
    }

    const onKeyUp = (e: any) => {
        if (e.key === 'Control') {
            setCtrlKeyPressed(false)
        }
    }

    useEffect(() => {
        if (showAddModal) {
            window.addEventListener('keydown', onKeyDown)
            window.addEventListener('keyup', onKeyUp)
        } else {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, [showAddModal]);

    useEffect(() => {
        if (newTagInputRef.current) {
            newTagInputRef.current.focus()
        }
    }, [ newTagInputRef ]);

    const sortTags = (a: string, b: string) => {
        return a > b ? 1 : -1
    }

    return (
        <>
            { video?.tags?.length || showAdd ? (
                <ul className="taglist main">
                    { video?.tags?.sort(sortTags).map(tag => (
                        <li key={ tag }>
                            <Link to={`/tags/${tag}`} onClick={ e => e.stopPropagation() }>
                                { tag }
                            </Link>
                        </li>
                    ))}
                    { showAdd ? (
                        <li>
                            <a href="#" onClick={ onAddClick }>
                                <FontAwesomeIcon icon={ faEdit }/>
                            </a>
                        </li>
                    ) : <></> }
                </ul>
            ) : <></> }

            { showAddModal ? createPortal(
                <Modal title="Update tags" onClose={ () => setShowAddModal(false) }>
                    <ul className="taglist">
                        { newTags.map(tag => (
                            <li key={ tag }>
                                { tag }
                                <a href="#" onClick={ e => removeTag(e, tag) }>
                                    <FontAwesomeIcon icon={ faTimes }/>
                                </a>
                            </li>
                        ))}
                    </ul>

                    <input type="text" ref={ newTagInputRef } onKeyUp={ onInputKeyUp } list="tags" placeholder={'New Tag'} disabled={saving}/>
                    <datalist id="tags">
                        { tags.map(tag => (
                            <option key={ tag } value={ tag }/>
                        ))}
                    </datalist>

                    <button onClick={ save } disabled={ saving }>
                        { saving ? 'Saving...' : 'Save' }
                    </button>
                </Modal>
            , document.body) : <></> }
        </>
    )
}