import {createRef, useEffect, useRef, useState} from "react";

import './VideoSeekBar.scss'
import {formatSeconds} from "../../lib/static";

type Props = {
    video: VideoFile
    currentTime: number | null
    totalTime: number | null
    seekTo: (ts: number) => void
}

type BarHoverType = {
    left: number
    timestamp: number
}

export default ({ video, currentTime, totalTime, seekTo }: Props) => {
    const ref = createRef<HTMLDivElement>()
    const barRef = createRef<HTMLDivElement>()

    const [ spriteData, setSpriteData ] = useState<SpriteData|null>(null)
    const [ preview, setPreview ] = useState<{ url: string, x: number, y: number, seek: number } | null>(null)
    const [ elapsedWidth, setElapsedWidth ] = useState<number>(0)
    const [ barHover, setBarHover ] = useState<BarHoverType|null>(null)

    const [ resizeToggle, setResizeToggle ] = useState<boolean>(false)
    const resizeToggleRef = useRef<boolean>()
    resizeToggleRef.current = resizeToggle


    useEffect(() => {
        const set = () => {
            setResizeToggle(!resizeToggleRef)
        }
        window.addEventListener('resize', set)

        return () => window.removeEventListener('resize', set)
    }, []);

    useEffect(() => {
        setSpriteData(null)

        if (video.paths.sprite) {
            fetch(video.paths.sprite)
                .then(res => res.json())
                .then(res => {
                    setSpriteData(res)
                })
        }
    }, [ video ]);

    useEffect(() => {
        if (!ref.current || totalTime === null || currentTime === null) {
            setElapsedWidth(0)
            return
        }

        const factory = currentTime / totalTime
        const width = ref.current.getBoundingClientRect().width
        setElapsedWidth(width * factory)

    }, [ totalTime, currentTime, resizeToggle ]);

    const barMouseMove = (e: any) => {
        if (totalTime === null) {
            setBarHover(null)
            return
        }

        const bar = barRef.current as HTMLDivElement
        const rect = bar.getBoundingClientRect()
        const fullWidth = rect.width

        let left = 0
        if (e.type === "touchmove") {
            left = e.touches[0].pageX - rect.left
        } else {
            left = e.pageX - rect.left
        }

        const factor = left / fullWidth
        const timestamp = totalTime * factor

        setBarHover({
            left,
            timestamp,
        })

        if (spriteData) {
            const frameIndex = Math.floor(timestamp / spriteData.durationPerFrame);
            const col = frameIndex % spriteData.framesPerPageX;
            const row = Math.floor(frameIndex / spriteData.framesPerPageX);

            const offsetX = -col * spriteData.frameWidth
            const offsetY = -row * spriteData.frameHeight

            setPreview({
                url: spriteData.url,
                x: offsetX,
                y: offsetY,
                seek: left,
            })
        }
    }

    const barMouseOut = () => {
        setBarHover(null)
        setPreview(null)
    }

    const barTouchOut = (e: any) => {
        if (totalTime === null) {
            setBarHover(null)
            return
        }

        const bar = barRef.current as HTMLDivElement
        const rect = bar.getBoundingClientRect()
        const fullWidth = rect.width

        const left = e.changedTouches[0].pageX - rect.left

        const factor = left / fullWidth
        const timestamp = totalTime * factor

        seekTo(timestamp)

        setBarHover(null)
        setPreview(null)
    }

    const barClick = (e: any) => {
        if (totalTime !== null && barHover) {
            const bar = barRef.current as HTMLDivElement
            const rect = bar.getBoundingClientRect()
            const fullWidth = rect.width
            const left = e.clientX - rect.left
            const factor = left / fullWidth
            const timestamp = totalTime * factor

            seekTo(timestamp)
        }
    }

    let layerStyle: React.CSSProperties = {}

    if (preview) {
        layerStyle = {
            position: 'absolute',
            left: `${preview.seek}px`,
            bottom: '40px',
            pointerEvents: 'none',
            backgroundImage: `url(${preview.url})`,
            width: `${spriteData?.frameWidth}px`,
            height: `${spriteData?.frameHeight}px`,
            backgroundPosition: `${preview.x}px ${preview.y}px`,
            transform: `translateX(-50%)`
        }
    }

    return (
        <div className="seek-bar"
             ref={ref}
             style={{ position: 'relative' }}
             onMouseMove={ barMouseMove }
             onMouseOut={ barMouseOut }
             onTouchMove={ barMouseMove }
             onTouchEnd={ barTouchOut }
             onClick={ barClick }>

            { barHover && (
                <div className="hover-timestamp" style={{ left: `${barHover.left}px` }}>
                    { formatSeconds(barHover.timestamp) }
                </div>
            ) }
            <div className="bar" ref={barRef}>
                <div className="elapsed" style={{ width: `${elapsedWidth}px` }}></div>
            </div>
            { preview && (
                <div style={ layerStyle }/>
            )}
        </div>
    )
}