import {useContext} from "react";
import AppContext from "../context/AppContext";

type Props = {
    timestamp: number
    now: number
}

function millisecondsToStr( milliseconds: number ) {
    let temp = milliseconds / 1000;
    const years = Math.floor( temp / 31536000 ),
        days = Math.floor( ( temp %= 31536000 ) / 86400 ),
        hours = Math.floor( ( temp %= 86400 ) / 3600 ),
        minutes = Math.floor( ( temp %= 3600 ) / 60 ),
        seconds = temp % 60;

    if ( days || hours || seconds || minutes ) {
        return ( years ? years + "y " : "" ) +
            ( days ? days + "d " : "" ) +
            ( hours ? hours + "h " : ""  ) +
            ( minutes ? minutes + "m " : "" ) +
            seconds + "s";
    }

    return "< 1s";
}

export default ({ timestamp, now }: Props) => {
    const seconds = millisecondsToStr((now - timestamp) * 1000)

    return (
        <>{ seconds }</>
    )
}