import {useContext} from "react";
import AppContext from "../context/AppContext";

import './ProcessListBubble.scss'
import {Link} from "react-router-dom";

export default () => {
    const { processList } = useContext(AppContext)

    return (
        <>
            { processList && (processList.queued.length || processList.processing.length) ? (
                <Link to="/queue" id="processlist-indicator">
                    <>{ processList.queued } queued, { processList.processing.length } processing&hellip;</>
                </Link>
            ) : <></> }
        </>
    )
}