import {ReactNode} from "react";

import './Modal.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

type Props = {
    title: string
    children: ReactNode
    onClose: () => void
}

export default ({ title, children, onClose }: Props) => {
    const onCloseClick = (e: any) => {
        e.preventDefault()
        onClose()
    }

    return (
        <div className="modal">
            <div className="modal-header">
                <div className="title">
                    { title }
                </div>
                <a href="#" className="btn-close" onClick={ onCloseClick }>
                    <FontAwesomeIcon icon={ faClose }/>
                </a>
            </div>
            <div className="modal-content">
                { children }
            </div>
        </div>
    )
}