export function formatSeconds (value: number|null, fracDigits: number|null = null): string {
    if (value === null) {
        return ''
    }
    let isNegative = false;
    if (isNaN(value)) {
        return "" + value;
    } else if (value < 0) {
        isNegative = true;
        value = Math.abs(value);
    }
    const days = Math.floor(value / 86400);
    value %= 86400;
    const hours = Math.floor(value / 3600);
    value %= 3600;
    const minutes = Math.floor(value / 60);
    let seconds = (value % 60).toFixed(fracDigits || 0);
    if (parseFloat(seconds) < 10) {
        seconds = '0' + seconds;
    }

    let res = hours ? (hours + ':' + ('0' + minutes).slice(-2) + ':' + seconds) : (minutes + ':' + seconds);
    if (days) {
        res = days + '.' + res;
    }
    return (isNegative ? ('-' + res) : res);
}

export function getVideoLink(id: string) {
    return window.location.href.split('?')[0] + '?show=' + id
}