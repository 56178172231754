import Modal from "../general/Modal";
import {createRef, useContext, useEffect, useState} from "react";
import AppContext from "../../context/AppContext";
import {useApi} from "../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

type Props = {
    videos: VideoFile[]
    onClose: () => void
    onChanged: (newTags: string[]) => void
}

export default ({ videos, onClose, onChanged }: Props) => {
    const api = useApi()

    const [ saving, setSaving ] = useState<boolean>(false)

    const removeTag = async (e: any, tag: string) => {
        if (!window.confirm(`Would you really like to remove the tag "${tag}"?`)) {
            return
        }

        if (typeof e !== 'undefined') {
            e.preventDefault()
            e.stopPropagation()
        }

        setSaving(true)

        const res: any = await api.bulkRemoveTags(videos.map(v => v.hash), [tag])

        if (typeof res.error !== 'undefined') {
            setSaving(false)
            alert(res.error)
            return
        }

        onChanged([tag])

        setSaving(false)

        onClose()
    }

    const existingTags = videos.reduce<string[]>((tags, video) => {
        for (const tag of video.tags) {
            if (!tags.includes(tag)) {
                tags.push(tag)
            }
        }
        return tags
    }, [])

    return (
        <Modal title="Remove tags" onClose={onClose}>
            <ul className="taglist">
                {existingTags.map(tag => (
                    <li key={tag}>
                        {tag}
                        <a href="#" onClick={e => removeTag(e, tag)} className={ saving ? 'disabled' : '' }>
                            <FontAwesomeIcon icon={faTimes}/>
                        </a>
                    </li>
                ))}
            </ul>
        </Modal>
    )
}